import * as React from "react"
// import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'
import AniLink from "gatsby-plugin-transition-link/AniLink";
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

const EventsPage = ({ data }) => {

	const page = data.wpMainPage;
	const events = data.allWpEventType.nodes;	

	const sortedEvents = events.sort((a, b) => {
		let ppl1 = a.menuOrder+1;
		let ppl2 = b.menuOrder+1;
		return (ppl1 < ppl2 ? -1 : 0)
	})

	console.log(sortedEvents);

	return (
		<Layout title={page.title} breadcrumbs={[['Events','#']]}>
			<Seo post={page} />
			<section className="content" id="events">
				<div id="eventsWrapper">
					{sortedEvents.map((event, i) => {
						return (
							<div key={i} className="event">
								<AniLink
									to={event.uri}
									cover bg="#EE82BF"
									direction="right"
									duration={0.8}
								>									
									<h3>{event.title}</h3>
								</AniLink>
							</div>
						)
					})}
				</div>

			</section>
		</Layout>
	)
}

export default EventsPage

export const query = graphql`
	query EventsPageQuery {
		allWpEventType(sort: {fields: menuOrder, order: ASC}) {
			nodes {
			  title
			  content
			  uri
			  menuOrder
			  featuredImage {
				node {
					sourceUrl
					localFile {
						childImageSharp {
							gatsbyImageData(
								placeholder: NONE
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
			}
			}
		}
		wpMainPage(databaseId: {eq: 2839}) {
			content
			nodeType
			title
			uri
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
	}
`;